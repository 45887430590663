.cb-rental-agreement-periods {
  height: calc(100% - 20px);
  overflow: auto;
  &.error-page {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: var(--p-10);
    text-align: center;
    color: var(--color-negative);
  }

  .periods-container {
    position: relative;
    padding-top: var(--p-10);
    padding-bottom: var(--p-10);
    min-height: 100%;
    .periods-action {
      display: flex;
      width: 100%;
      justify-content: flex-end;
      padding: 0px 15px 10px 15px;
    }
    .vert-line {
      width: 1px;
      background-color: var(--background-color-accent);
      height: 100%;
      position: absolute;
      left: 20px;
      top: 0;
      z-index: 0;
    }

    .cb-rental-agreement-period {
      margin-left: var(--m-10);
      padding-bottom: var(--p-15);

      .head-line {
        position: relative;
        border-bottom: solid 1px var(--border-color-tertiary);
        padding-left: var(--p-10);
        padding-right: var(--p-10);
        color: var(--text-color-primary);
        display: flex;
        align-items: center;
        .current-period,
        .future-period {
          color: var(--text-color-secondary);
          padding-right: var(--p-3);
          font-weight: 600;
          font-size: var(--font-size-sm);
          letter-spacing: 1px;
          text-transform: uppercase;
        }
        .reason {
          padding-left: var(--p-5);
          color: var(--text-color-primary);
          font-size: var(--font-size-base);
        }

        .circle {
          position: absolute;
          left: -10px;
          top: 10px;
          width: 20px;
          height: 20px;
          border-radius: var(--border-radius-rounded);
          background-color: var(--background-color-contrast);
          border: 1px solid var(--border-color-tertiary);
          z-index: 1;
          display: flex;
          justify-content: center;
          align-items: center;
          .current-circle {
            width: 14px;
            height: 14px;
            border-radius: var(--border-radius-rounded);
            background-color: var(--base-color);
          }
          .past-circle {
            width: 14px;
            height: 14px;
            border-radius: var(--border-radius-rounded);
            background-color: var(--background-color-secondary);
          }
        }
      }
      &.current {
        .head-line {
          border-color: var(--base-color);
          border-width: 1px;
        }
      }
      .period-content {
        padding-top: var(--p-5);
        padding-left: var(--p-10);

        .cb-rental-agreement-rental-unit-entry {
          padding-top: 1px;
          padding-bottom: 1px;
        }
        .__h4 {
          padding-bottom: var(--p-3);
        }
        .entry-list {
          display: flex;
          flex-wrap: wrap;
          gap: 10px 20px;
          padding-bottom: var(--p-5);
          .entry {
            .label {
              font-size: var(--font-size-sm);
              color: var(--text-color-secondary);
            }
            .value {
              font-size: var(--font-size-md);
              .plan {
                font-size: var(--font-size-sm);
                color: var(--text-color-secondary);
              }
            }
          }
        }
      }
    }
  }
}
