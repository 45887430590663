.bf-virtualized-table {
  --border-color: var(--border-color-primary);
  --fixed-border: var(--border-color-contrast-inverse);
  --border-fixed: var(--border-color-secondary);

  --fixed-inset-box-shadow: 0 0 6px var(--shadow-contrast-inverse-4);
  --fixed-inset-left-clip-path: inset(0px -6px 0px 0px);

  --fixed-inset-right-clip-path: inset(0px 0px 0px -6px);

  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  &.hide-header {
    .bf-virtualized-table-header {
      display: none;
    }
  }
  .ListItemEven {
    .table-row {
      background: var(--table-row-color);
    }
  }
  &.ignore-striped {
    .ListItemEven {
      .table-row {
        background: var(--table-row-color);
      }
    }
  }
  .bf-virtualized-table-header {
    display: flex;
    .main-table-header-container,
    .left-table-header-container,
    .right-table-header-container {
      overflow-x: hidden;
      position: relative;
      .table-header {
        display: flex;
        height: 100%;
        min-width: 100%;

        .header-column {
          border-right: solid 1px var(--border-color);
          display: flex;
          align-items: center;
          position: relative;

          button.label {
            max-width: 100%;
            text-overflow: ellipsis;
            .label-span {
              max-width: 100%;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
          &.alignment-left {
            .label {
              text-align: left;
              justify-content: flex-start;
            }
          }
          &.alignment-center {
            .label {
              text-align: center;
              justify-content: center;
            }
          }
          &.alignment-right {
            .label {
              justify-content: flex-end;
              text-align: right;
            }
          }
          &:last-of-type {
            border-right: none;
          }
          &.sortable {
            cursor: pointer;
            &:hover {
              background-color: var(--background-color-primary);
            }
          }
          &.sorted {
            font-weight: bold;
          }
          .label {
            padding: 7px 5px;
            display: inline-flex;
            align-items: center;
            flex-grow: 1;
            height: 100%;
            min-height: 100%;
            text-align: left;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            background: transparent;
            &:hover {
              background: var(--background-color-primary);
            }
          }
          .sort-indicator {
            padding-right: var(--p-3);
            .bf-icon {
              width: 15px;
              height: 15px;
            }
          }
          button.bf-virtualized-table-header-context-menu {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            opacity: 0;
            transition: opacity 0.2s;
            background-color: var(--background-color-primary);
            &:hover {
              background-color: var(--background-color-secondary);
            }
          }
          &:hover {
            .bf-virtualized-table-resize-handler {
              opacity: 1;
            }
            button.bf-virtualized-table-header-context-menu {
              opacity: 1;
            }
          }
          .bf-virtualized-table-resize-handler {
            position: absolute;
            opacity: 0;
            transition: opacity 0.2s ease;
            right: 0px;
            top: 0px;
            bottom: 0px;
            width: 8px;
            border-right: dashed var(--border-color-tertiary) 2px;
            cursor: col-resize;
            &.active {
              opacity: 1;
              border-right: solid var(--border-color-accent) 2px;
            }
          }
        }
      }
    }

    .main-table-header-container {
      padding-right: var(--p-4);
      flex-grow: 1;
    }

    .left-table-header-container {
      border-right: solid 1px var(--border-fixed);
      flex-shrink: 0;

      box-shadow: var(--fixed-inset-box-shadow);
      clip-path: var(--fixed-inset-left-clip-path);
    }
    .right-table-header-container {
      border-left: solid 1px var(--border-fixed);
      flex-shrink: 0;
      box-shadow: var(--fixed-inset-box-shadow);
      clip-path: var(--fixed-inset-right-clip-path);
    }
  }
  .bf-virtualized-table-footer {
    display: flex;
    .main-table-footer-container,
    .left-table-footer-container,
    .right-table-footer-container {
      overflow: hidden;
      position: relative;
      .table-footer {
        display: flex;
        min-width: 100%;
        .footer-column {
          padding: 7px 5px;
          border-right: solid 1px var(--border-color);
          display: flex;
          align-items: center;
          position: relative;

          .content {
            max-width: 100%;
            overflow: hidden;
            .cell.cell-value {
              max-width: 100%;
              white-space: nowrap;
              display: block;
              max-width: 100%;
            }
          }
          &.alignment-left {
            justify-content: flex-start;
            .content {
              text-align: left;
            }
          }
          &.alignment-center {
            justify-content: center;
            .content {
              text-align: center;
            }
          }
          &.alignment-right {
            justify-content: flex-end;
            .content {
              text-align: right;
            }
          }
          &:last-of-type {
            border-right: none;
          }
          &.sorted {
            font-weight: bold;
          }
        }
      }
    }

    .main-table-footer-container {
      padding-right: var(--p-4);
      flex-grow: 1;
    }

    .left-table-footer-container {
      border-right: solid 1px var(--border-fixed);
      flex-shrink: 0;

      box-shadow: var(--fixed-inset-box-shadow);
      clip-path: var(--fixed-inset-left-clip-path);
    }
    .right-table-footer-container {
      border-left: solid 1px var(--border-fixed);
      flex-shrink: 0;
      box-shadow: var(--fixed-inset-box-shadow);
      clip-path: var(--fixed-inset-right-clip-path);
    }
  }
  .table-content {
    padding-top: var(--p-1);
    flex-grow: 1;
    height: 0;
    display: flex;
    position: relative;
    .loading-overlay {
      z-index: 2;
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      left: 0;
      bottom: 0;
      right: 0;
      top: 0;
      background: var(--background-opacity-primary);
    }
    .loading-bottom-container {
      pointer-events: none;
      z-index: 2;

      position: absolute;
      bottom: 10px;
      left: calc(50% - 20px);

      .loading-bottom {
        width: 40px;
        height: 40px;
        border-radius: var(--border-radius-base);
        display: flex;
        justify-content: center;
        align-items: center;
        background: var(--background-opacity-primary);
      }
    }
    .bf-virtualized {
      height: 100%;
      &.main {
        flex-grow: 1;
      }
      &.left {
        border-right: solid 1px var(--border-fixed);

        box-shadow: var(--fixed-inset-box-shadow);
        clip-path: var(--fixed-inset-left-clip-path);
        z-index: 1;
        &::-webkit-scrollbar {
          display: none;
        }
        &*::-webkit-scrollbar-thumb {
          display: none;
        }
        padding-bottom: var(--p-5);
      }
      &.right {
        border-left: solid 1px var(--border-fixed);
        box-shadow: var(--fixed-inset-box-shadow);
        clip-path: var(--fixed-inset-right-clip-path);
        z-index: 1;
        &::-webkit-scrollbar {
          display: none;
        }
        &*::-webkit-scrollbar-thumb {
          display: none;
        }
        padding-bottom: var(--p-5);
      }

      .table-row {
        position: relative;
        min-width: 100%;
        height: 100%;
        width: fit-content;
        border-bottom: solid 1px var(--border-color);
        color: var(--table-row-foreground);
        .row-columns {
          position: relative;
          display: flex;
          min-width: 100%;
          max-height: 100%;
          height: 100%;
          width: fit-content;
          align-items: center;
        }
        &.selected {
          background-color: var(--table-row-color-selected);
          color: var(--table-row-foreground-selected);

          &.hovered {
            background-color: var(--table-row-color-selected);
          }
          .__color-positive {
            color: var(--color-positive);
          }
          .color-negative,
          .__color-negative {
            color: var(--color-negative);
          }
        }
        > .row-overlay {
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
        }
        &.clickable {
          .table-column {
            cursor: pointer;
            &.ignore-row-click {
              cursor: default;
            }
          }
        }
        &.hovered {
          background-color: var(--table-row-color-hover);
        }
        .table-column {
          border-right: solid 1px var(--border-color);
          height: 100%;
          padding-left: var(--p-3);
          padding-right: var(--p-3);
          display: flex;
          // overflow: hidden;
          align-items: center;

          &.alignment-left {
            justify-content: flex-start;
          }
          &.alignment-center {
            justify-content: center;
          }
          &.alignment-right {
            justify-content: flex-end;
          }
          text-overflow: ellipsis;
          white-space: nowrap;
          &:last-of-type {
            border-right: none;
          }
          &.no-padding {
            padding: var(--p-0);
          }
        }

        .drag-overlay {
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          background: var(--drag-overlay-background);
          border: solid 1px var(--drag-overlay-border);

          z-index: 1;
          pointer-events: none;
        }
      }
    }

    .inset-shadow {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0px;
      box-shadow: var(--default-inset-shadow);
      pointer-events: none;
      z-index: 1;
    }
  }

  &.ignore-column-border {
    .bf-virtualized-table-header {
      .main-table-header-container,
      .left-table-header-container,
      .right-table-header-container {
        .table-header {
          .header-column {
            border-right: none;
          }
        }
      }
    }
    .bf-virtualized-table-footer {
      .main-table-footer-container,
      .left-table-footer-container,
      .right-table-footer-container {
        .table-footer {
          .footer-column {
            border-right: none;
          }
        }
      }
    }

    .table-content {
      .bf-virtualized {
        .table-row {
          .table-column {
            border-right: none;
          }
        }
      }
    }
  }
  &.ignore-row-border {
    .table-content {
      .bf-virtualized {
        .table-row {
          border-bottom: none;
        }
      }
    }
  }

  .data-aggregation-icon {
    background-color: var(--rs-bg-overlay);
    border-radius: var(--border-radius-base);
    bottom: 10px;
    box-shadow: 0 0 10px var(--shadow-contrast-inverse-6);
    height: 40px;
    left: calc(100% - 60px);
    padding: var(--p-3);
    position: absolute;
    width: 40px;
    z-index: 2;

    > .bf-icon {
      width: 100%;
      height: 100%;
    }
  }

  .data-aggregation-container {
    // display: flex;
    // flex-grow: 1;
    z-index: 2;
    box-shadow: 0 0 10px var(--shadow-contrast-inverse-6);
    position: absolute;
    bottom: 10px;
    right: 10px;
    width: auto;
    height: auto;
    background-color: var(--rs-bg-overlay);
    border: var(--rs-modal-border, none);
    border-radius: var(--border-radius-large);
    padding: 1rem;

    > button {
      left: calc(100% - 15px);
      padding: var(--p-0);
      margin-bottom: -20px;
    }
    > .bf-value-display {
      white-space: nowrap;
    }
  }
}

.bf-virutalized-table-header-context-menu-options {
  display: flex;
  flex-direction: column;

  .column-name {
    padding-left: var(--p-3);
    padding-right: var(--p-3);
    border-bottom: solid var(--border-color-tertiary) 1px;
    pointer-events: none;
    color: var(--text-color-secondary);
    padding-bottom: var(--p-5);
    padding-top: var(--p-5);
    margin-bottom: var(--m-5);
  }
  .bf-button {
    border-radius: var(--border-radius-none);
    width: 100%;
    background: transparent;
    justify-content: flex-start;
    &:hover {
      background: var(--background-color-primary);
    }
  }
  .divider {
    width: 100%;
    border-bottom: solid 1px var(--border-color-tertiary);
    padding-bottom: var(--p-5);
    margin-bottom: var(--m-5);
  }
  .subtitle {
    padding-left: var(--p-3);
    padding-right: var(--p-3);
    width: 100%;
    font-size: var(--font-size-sm);
    color: var(--text-color-secondary);
  }
}
